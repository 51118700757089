import React, { Fragment, useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import {
  CardBody,
  Card,
  CardHeader,
  Container,
  Col,
  Row,
  Collapse,
  Label,
  Table,
} from "reactstrap";
import { Accordion } from "react-bootstrap";
import { H2, H4, H5 } from "../../../AbstractElements";
import { useSelector, useDispatch } from "react-redux";
import api from "../../../_helper/api_helper";
import { toast } from "react-toastify";
import { FETCH_ALL_UNDER_CLIENTS_URL, DELETE_CLIENT_URL, DOWNLOAD_CLIENT_CSV_URL } from "../../../_helper/url_helper";
import { useNavigate } from "react-router-dom";

const GeneralComponent = () => {
  const navigate = useNavigate();
  const loginState = useSelector((state) => state.login);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, total: 1, totalRows: 0 });
  const [clientId, setClientId] = useState("");
  const [username, setUsername] = useState("");
  const [status, setStatus] = useState("all");
  const [contact_no, setContact] = useState("");
  const [name, setName] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  // const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));
  //fetch first time users
  useEffect(() => {
    fetchClientsHandler();
  }, []);

  useEffect(() => {
    fetchClientsHandler();
  }, [username, status, contact_no]);

  function setPage(page) {
    if (page < 1 || page > pagination.total) {
      return;
    }
    // setPagination({ ...pagination, current: page });
    fetchClientsHandler(page);
  }

  async function fetchClientsHandler(page = 1) {
    try {
      let data = {};
      if (username !== "") {
        data["username"] = username;
      }
      if (status !== "all") {
        data["status"] = status;
      }
      if (contact_no !== "") {
        data["contact_no"] = contact_no;
      }
      data.page = page;
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = await api.get(`${FETCH_ALL_UNDER_CLIENTS_URL}`, { params: data });
      if (res.data.status === "SUCCESS") {
        setData(res.data.payload.data);
        setPagination({ current: res.data.payload.current_page, total: res.data.payload.last_page, totalRows: res.data.payload.total })
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }


  async function downloadClientsCsvHandler() {
    try {
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = await api.get(`${DOWNLOAD_CLIENT_CSV_URL}`);
      console.log("Hi");
      if (res.status === 200) {
        // Create a blob from the response data
        const blob = new Blob([res.data], { type: 'text/csv' });

        // Create a URL for the blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'clients.csv');

        // Append the link to the body
        document.body.appendChild(link);

        // Click the link to trigger the download
        link.click();

        // Remove the link from the body
        document.body.removeChild(link);

      } else {
        toast.error("Failed to fetch data");
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function deleteClientHandler(clientId) {
    try {
      if (!window.confirm("Areyou sure?")) {
        return;
      }
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let data = {
        // jwttoken: loginState.jwttoken,
        client_id: clientId
      };

      let res = await api.post(DELETE_CLIENT_URL, data);
      if (res.data.status === "SUCCESS") {
        fetchClientsHandler();
        toast.success("Client Deleted");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  const tableColumns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
      width: "120px",
      center: true,
    },
    {
      name: "Name",
      selector: (row) => row.firstname + " " + row.lastname,
      sortable: true,
      center: true,
      width: "130px",
    },
    {
      name: "Username",
      selector: (row) => row.username,
      width: "130px",
      sortable: true,
      center: true,
    },
    {
      name: "Broker",
      selector: (row) => row.broker,
      width: "130px",
      sortable: true,
      center: true,
    },
    {
      name: "Ledger",
      selector: (row) => row.balance,
      sortable: true,
      center: true,
      width: "130px",
    },
    {
      name: "Goss PL",
      selector: (row) =>
        !row.profit_and_loss ? (
          <i className="icofont icofont-emo-slightly-smile"></i>
        ) : row.profit_and_loss > 0 ? <span className="text-success">{row.profit_and_loss}</span>
          : <span className="text-danger">{row.profit_and_loss}</span>,
      sortable: true,
      center: true,
    },
    {
      name: "Brokerage",
      selector: (row) => row.brokerage,
      sortable: true,
      center: true,
      width: "140px"
    },
    {
      name: "Net PL ",
      selector: (row) => Number(row.profit_and_loss) - Number(row.brokerage),
      sortable: true,
      center: true,
      width: "130px",
    },
    {
      name: "Is Demo",
      selector: (row) => row.is_demo,
      sortable: true,
      center: true,
      width: "130px",
    },
    {
      name: "Status",
      selector: (row) =>
        row.status === "active" ? (
          <span className="badge bg-success">active</span>
        ) : (
          <span className="badge bg-danger">inactive</span>
        ),
      sortable: true,
      center: true,
      width: "130px",
    },

    // {
    //   name: (
    //     <span className="text-center fw-bold">
    //       P&L <br /> Share(%)
    //     </span>
    //   ),
    //   selector: (row) => (row.profit_loss_share !== undefined)?row.profit_loss_share:<i className="icofont icofont-emo-slightly-smile"></i>,
    //   sortable: true,
    //   center: true,
    //   width: "130px",
    // },
    {
      name: "Action",
      selector: (row) => actionBinder(row, navigate, deleteClientHandler),
      width: "190px",
      sortable: true,
      center: true,
    },
  ];

  const paginationComponentOptions = {
    rowsPerPageText: "Users per page",
  };

  return (
    <Fragment>
      <Container fluid={true} className="datatables">
        <Row>
          <Col sm="12">
            <Accordion defaultActiveKey="0">
              <div className="default-according" id="accordion1">
                <Card className="mb-4">
                  <CardHeader className="bg-secondary" onClick={() => setIsOpen(!isOpen)}>
                    <h5 className="d-flex justify-content-between">
                      <span>Filters</span>
                      {isOpen ? (
                        <i className="icofont icofont-rounded-up"></i>
                      ) : (
                        <i className="icofont icofont-rounded-down"></i>
                      )}
                    </h5>
                  </CardHeader>
                  <Collapse isOpen={isOpen}>
                    <CardBody>
                      <Row>
                        <Col md={3}>
                          <Label>
                            USERNAME
                          </Label>
                          <input type="text" className="form-control" placeholder="Enter Username" value={username} onChange={(e) => setUsername(e.target.value)} />
                        </Col>

                        <Col md={3}>
                          <Label>Status</Label>
                          <select className="form-control" value={status} onChange={(e) => { setStatus(e.target.value) }}>
                            <option value="all">All</option>
                            <option value="active">Active</option>
                            <option value="inactive">InActive</option>
                          </select>
                        </Col>

                        <Col md={3}>
                          <Label>
                            CONTACT NO
                          </Label>
                          <input type="number" className="form-control" placeholder="Enter contact number" value={contact_no} onChange={(e) => setContact(e.target.value)} />
                        </Col>

                        <div className="col row align-items-end">
                          <div>
                            {(username !== "" || status !== "all" || contact_no !== "") && <button className="btn btn-danger m-t-5" onClick={() => { setUsername(""); setStatus("all"); setContact("") }}>
                              Reset
                            </button>}
                          </div>
                        </div>
                        <div className="col"></div>
                      </Row>
                    </CardBody>
                  </Collapse>
                </Card>
              </div>
            </Accordion>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="p-b-0">
                <div className="justify-content-between row">
                  <div className="col-4 col">
                    <h5 className="m-0 p-0 d-inline-block">
                      <i className="fa fa-users"></i> Clients{" "}
                    </h5>{" "}
                    <span className="badge rounded-pill badge bg-primary rounded-pill">
                      {pagination.totalRows}
                    </span>
                  </div>
                  <div className="col-4 col text-end">
                    <button
                      className="btn btn-secondary me-2"
                      onClick={() => downloadClientsCsvHandler()}
                    >
                      Download
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={() => navigate("/client/create")}
                    >
                      <i className="fa fa-plus"></i> Create
                    </button>
                  </div>
                </div>
              </CardHeader>

              <CardBody>
                <div className="table-responsive">
                  <table className="table-border-horizontal w-100 fw-semibold table-striped text-nowrap">                  <thead>
                    <tr>
                      <td>ID</td>
                      <td>NAME</td>
                      <td>USERNAME</td>
                      <td>BROKER</td>
                      <td>LEDGER</td>
                      <td>USD LEDGER</td>
                      <td>IS DEMO</td>
                      <td>STATUS</td>
                      <td>ACTIONS</td>
                    </tr>
                  </thead>
                    <tbody>
                      {data.map((client) => {
                        return (
                          <>
                            <tr>
                              <td>{client.id}</td>
                              <td>{client.firstname + " " + client.lastname}</td>
                              <td>{client.username}</td>
                              <td>{client.broker}</td>
                              <td className="text-success">{client.client_account.balance}</td>
                              <td className="text-success">{client.client_account.balance_usd}</td>
                              <td>{client.client_account.is_demo === "yes" ? <span className="badge badge-secondary">Demo</span> : <span className="badge badge-primary">Real</span>}</td>
                              <td>{client.status === "active" ? <span className="badge badge-success">active</span> : <span className="badge badge-danger">inactive</span>}</td>
                              <td>{actionBinder(client, navigate, deleteClientHandler)}</td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="pagination mt-3 d-flex justify-content-between align-items-center" >
                  <div>
                    <span className="text-semibold">Page {pagination.current} out of {pagination.total}</span>
                  </div>
                  <div>
                    <button className="btn btn-outline-primary me-2" onClick={() => { setPage(1) }}>First</button>
                    <button className="btn btn-outline-primary" onClick={() => { setPage(pagination.current - 1) }}>Prev</button>
                    <input type="number" className="form-control d-inline mx-3 text-center fw-semibold" style={{ maxWidth: '100px' }} min="1" value={pagination.current} onChange={(e) => { setPage(e.target.value) }} />
                    {/* <span>of</span> */}
                    {/* <span id="totalPages">10</span> */}
                    <button className="btn btn-outline-primary" onClick={() => { setPage(pagination.current + 1) }}>Next</button>
                    <button className="btn btn-outline-primary ms-2" onClick={() => { setPage(pagination.total) }}>Last</button>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default GeneralComponent;

function actionBinder(row, navigate, deleteClientHandler) {
  return (
    <div>
      <ul className="list-inline hstack gap-1 mb-0">
        {/* <li className="list-inline-item">
          <button
            title="view"
            className="btn btn-sm btn-primary p-0 d-flex justify-content-center align-items-center fw-semibold"
            style={{ width: "30px", height: "30px" }}
            onClick={() => navigate(`/client/profile/${row.id}`)}
          >
            <i className="fa fa-eye"></i>
          </button>
        </li> */}
        <li className="list-inline-item edit">
          <button
            title="update"
            className="btn btn-sm btn-secondary p-0 d-flex justify-content-center align-items-center fw-semibold"
            style={{ width: "30px", height: "30px" }}
            onClick={() => navigate(`/client/Update/${row.id}`)}
          >
            <i className="fa fa-pencil"></i>
          </button>
        </li>
        <li className="list-inline-item edit">
          <button
            title="Copy"
            className="btn btn-sm btn-info p-0 d-flex justify-content-center align-items-center fw-semibold"
            style={{ width: "30px", height: "30px" }}
            onClick={() => navigate(`/client/Copy/${row.id}`)}
          >
            <i className="fa fa-copy"></i>
          </button>
        </li>
        <li>
          <button
            title="Delete"
            className="btn btn-sm btn-outline-danger p-0 d-flex justify-content-center align-items-center fw-semibold"
            style={{ width: "30px", height: "30px" }}
            onClick={() => { deleteClientHandler(row.id) }}
          >
            <i className="fa fa-trash-o"></i>
          </button>
        </li>
      </ul>
    </div>
  );
}
